.ag-color-basic {
  filter: hue-rotate(40deg) saturate(1) brightness(15%) saturate(1);
  -webkit-filter: hue-rotate(40deg) saturate(1) brightness(15%) saturate(1);
}

.ag-color-red {
  filter: hue-rotate(300deg) saturate(15) brightness(40%) saturate(15);
  -webkit-filter: hue-rotate(300deg) saturate(15) brightness(40%) saturate(15);
}

.ag-color-blue {
  filter: hue-rotate(96deg) saturate(105) brightness(21%) saturate(21);
  -webkit-filter: hue-rotate(96deg) saturate(105) brightness(21%) saturate(21);
}

.icon {
  display: inline-block;
  width: 33px;
  height: 32px;
  margin-right: 10px;
}

.icon-home {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -309px -128px;
}

.icon-calendar {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -19px -128px;
}

.icon-maps {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -69px -197px;
}

.icon-croppings {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -174px -197px;
}

.icon-certificates {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -226px -200px;
}

.icon-notification {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -18px -270px;
}

.icon-recommendation {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -65px -270px;
}

.icon-warning {
  background: url("./agrico_icons.svg") no-repeat;
  background-position: -121px -197px;
}

.menu-item:hover .icon {
  filter: hue-rotate(40deg) saturate(0.5) brightness(390%) saturate(4);
  -webkit-filter: hue-rotate(40deg) saturate(0.5) brightness(390%) saturate(4);
}

.menu-item-active .icon {
  filter: hue-rotate(40deg) saturate(0.5) brightness(390%) saturate(4);
  -webkit-filter: hue-rotate(40deg) saturate(0.5) brightness(390%) saturate(4);
}

// Tymczasowo
.alert {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 0, 0, 0.05);
}

.organization-item {
  .topbar-item {
    width: 100% !important;
    .navi-item {
      width: 100%;
    }
  }
}

.test_hider {
  display: none !important;
  visibility: hidden !important;
}

// Growers
.offer__block {
  text-decoration: none;
  color: #464e5f;
}

.offer__block:hover {
  span {
    color: #3699ff !important;
  }
}

.row--hidden {
  display: none;
  visibility: hidden;
}

.row__factory {
  background-color: #ffe2e5 !important;
}

.row__grower {
  background-color: #fff4de !important;
}

.agrico-svg-icon {
  svg {
    height: 2rem;
    width: 2rem;
  }
}
