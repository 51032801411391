$font-auth-color-secondary: #d6ddd2;
$font-auth-color-quaternary: #cdda66;

$font-trial-color: #f4f5ef;
$font-starter-color: #cbb735;
$font-standard-color: #f2b443;
$font-pro-color: #72b738;

$breakpoint-mobile: 768px;
$breakpoint-tablet: 768px;

$primary: #27ad7a;
$primary-brigadier: darken(#f2b544, 11);
$secondary: #bab735;

:root {
  --toastify-font-family: inherit !important;
  --toastify-color-success: #{$primary} !important;
  --toastify-color-error: rgb(168, 16, 116) !important;
  --toastify-color-warning: #ffa000 !important;
  --toastify-color-info: #17c191 !important;
  --toastify-color-progress-light: var(--toastify-text-color-light) !important;
}

/*********\
|   AUTH  |
\*********/

.auth__background {
  background-size: cover;
  background-color: #b9b738 !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.auth__panel {
  padding: 30px 0;
  position: relative;
}

@media screen and (max-width: 481px) {
  .auth__panel {
    width: 100%;
  }

  .auth__logo img {
    height: 100px;
  }
}

@media screen and (min-width: 482px) and (max-width: 768px) {
  .auth__panel {
    width: 50%;
  }
}

@media screen and (min-width: 769px) {
  .auth__panel {
    width: 33%;
  }
}

.auth__panel::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
}

.auth__logo {
  p {
    font-size: 1.25rem;
    text-align: center;
  }
  svg {
    height: 40px;
    @media (max-width: 1200px) {
      height: 35px;
    }
  }
}

.auth__form {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth__form * {
  z-index: 1;
}

.auth__welcoming h1 {
  margin-bottom: 0;
}

.auth__heading {
  margin-bottom: 15px;
}

.form {
  width: 100%;
  padding: 0 40px;
}

.auth__footer {
  width: 100%;
}

.auth__header {
  color: #000;
}

.auth__header {
  top: 90px;
  position: absolute;
}

.auth__text--normal {
  font-size: 18px;
}

.auth__text--big {
  font-size: 26px;
}

.auth__text--bold {
  font-weight: 400;
}

.button--auth_background_primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.button--auth_background_primary:hover {
  background-color: darken($primary, 15) !important;
  border-color: darken($primary, 15) !important;
}

.auth__bar {
  width: 43%;
  height: 1px;
  border-bottom: 1px solid $font-auth-color-secondary;
}

.auth__text--secondary {
  color: $font-auth-color-secondary;
}

.auth__link_text--color {
  color: $primary;
}

.auth__link_text--color:hover {
  color: darken($primary, 15);
}

.text--bold {
  font-weight: 500;
}

.text--uppercase {
  text-transform: uppercase;
}

.text-big {
  font-size: 24px;
}

.text--capitalize {
  text-transform: capitalize;
}

.button__social {
  min-width: 110px;
}

.button--twitter_background {
  background-color: #cdda66 !important;
  border-color: #cdda66 !important;
}

.button--twitter_background:hover {
  background-color: darken(#cdda66, 15) !important;
  border-color: darken(#cdda66, 15) !important;
}

.button--google_background {
  background-color: #d1c365 !important;
  border-color: #d1c365 !important;
}

.button--google_background:hover {
  background-color: darken(#d1c365, 15) !important;
  border-color: darken(#d1c365, 15) !important;
}

.button--gw_active_background {
  background-color: green !important;
  border-color: green !important;
  color: #fff !important;
}

.button--gw_disabled_background {
  opacity: 1 !important;
  background-color: yellow !important;
  border-color: yellow !important;
  color: #000 !important;
}

.checkbox__primary.checkbox--active .MuiIconButton-label {
  color: $primary !important;
}

.checkbox__fields.checkbox--active .MuiIconButton-label {
  color: darken($primary, 15) !important;
}

.checkbox__croppings.checkbox--active .MuiIconButton-label {
  color: darken(#32cd32, 15) !important;
}

.checkbox__treatments.checkbox--active .MuiIconButton-label {
  color: darken(#a52a2a, 15) !important;
}

.checkbox__soil_cultivation.checkbox--active .MuiIconButton-label {
  color: darken(#a55858, 15) !important;
}

.checkbox__fertilization.checkbox--active .MuiIconButton-label {
  color: darken(#ba0000, 15) !important;
}

.checkbox__planting.checkbox--active .MuiIconButton-label {
  color: darken(#abcd83, 15) !important;
}

.checkbox__plant_protection.checkbox--active .MuiIconButton-label {
  color: darken(#308050, 15) !important;
}

.checkbox__irrigation.checkbox--active .MuiIconButton-label {
  color: darken(#b1d8ff, 15) !important;
}

label.checkbox__fields--no_margin_bottom {
  margin-bottom: 0;
}

.pointer {
  cursor: pointer;
}

.table__header_row {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.table__header_row--active {
  color: #3699ff;
}

.table__header_icon {
  padding-left: 5px;
  font-size: 0.88rem;
  color: #3699ff;
}

.modal__content_header {
  display: block;
  color: $primary;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.2rem + 0.6vw);
}

.modal__center {
  display: block;
  text-align: center;
}

.button__link--primary {
  color: #fff;
}

.button__link--primary:hover {
  color: #fff;
}

.react-datepicker {
  font-family: inherit !important;
  &-wrapper {
    width: 100% !important;
    input {
      width: 100% !important;
      padding: 0.65rem 1rem;
      color: #464e5f;
      background-clip: padding-box;
      border: 1px solid #e5eaee;
      border-radius: 0.42rem;
      box-shadow: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .react-datepicker-ignore-onclickoutside {
      border-color: #69b3ff;
      outline: 0;
    }
  }
  &-popper {
    z-index: 2;
  }
}

.notification--padding {
  padding: 12px 10px 0px 10px;
}

.text--not_selectable {
  user-select: none;
}

.text--red {
  color: #e80e0e !important;
}

.text--green {
  color: #2db07d !important;
}

.cursor--pointer {
  cursor: pointer;
}

.form_auth {
  width: 45%;
}

.auth_links {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}

.button_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_bar--center {
  justify-content: center !important;
}

.button_auth {
  width: 45%;
}

@media (max-width: $breakpoint-mobile) {
  .agrico-bg {
    background-image: none !important;
  }

  .form_auth {
    width: 60%;
  }

  .auth_links {
    justify-content: center;
  }

  .button_bar {
    flex-direction: column;
  }

  .button_auth {
    width: 100%;
  }
}

.btn.btn-primary {
  color: #fff;
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn.btn-primary:hover:not(.btn-text),
.btn.btn-primary:focus:not(.btn-text),
.btn.btn-primary.focus:not(.btn-text) {
  color: #fff;
  background-color: darken($primary, 15) !important;
  border-color: darken($primary, 15) !important;
}

.btn.btn-outline-primary {
  color: $primary;
  background-color: transparent;
  border-color: $primary;
}

.btn.btn-outline-primary:hover:not(.btn-text),
.btn.btn-outline-primary:focus:not(.btn-text),
.btn.btn-outline-primary.focus:not(.btn-text) {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.btn.btn-outline-primary i {
  color: $primary;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-primary.dropdown-toggle,
.show .btn.btn-outline-primary.btn-dropdown {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.weather__button {
  max-width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff !important;
  border: 1px solid #fff !important;
}

.weather__button:focus {
  border: 1px solid #000 !important;
}

.weather__button_text {
  width: 100%;
}

.page-link {
  color: $primary;
}

.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}

.nav .show > .nav-link,
.nav .nav-link:hover:not(.disabled),
.nav .nav-link.active {
  color: $primary;
}

.page-link:hover {
  color: $primary;
}

.map {
  left: 0;
  top: 120px;
  position: absolute !important;
  &-mobile {
    @media (max-width: 991.98px) {
      height: calc(76vh - 40px) !important;
    }
    @media (max-width: 767.98px) {
      height: calc(69vh - 30px) !important;
    }
  }
}

.button--link {
  background-color: transparent !important;
  border: none !important;
}

.windowbox {
  &__heading--field {
    color: darken($primary, 15);
  }
  &__heading--cropping {
    color: darken(#32cd32, 15);
  }
  &__heading--treatment {
    color: darken(#a52a2a, 15);
  }
  &__paragraph {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: normal;
    > span:last-child {
      margin-left: 0.5rem;
      text-align: right;
    }
  }
}

.calendar--mini {
  font-family: Poppins, Helvetica, "sans-serif" !important;
  line-height: 1em !important;
  border: none !important;

  .react-calendar__navigation {
    margin-bottom: 0.5em !important;
  }

  .react-calendar__month-view__weekdays {
    font-weight: 600 !important;

    abbr {
      cursor: default;
      text-decoration: none;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0 !important;
  }

  .react-calendar__tile {
    padding: 0.55em 0.5em !important;
  }

  button.react-calendar__tile {
    font-size: 10px !important;
  }

  .react-calendar__tile--now {
    background-color: #44b480 !important;
  }

  .react-calendar__tile--now:hover {
    background-color: #2bac7c !important;
  }

  .react-calendar__tile--active {
    color: #fff !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }

  .react-calendar__tile--active:hover {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.fc-button.fc-button-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.fc-button.fc-button-primary:hover {
  background-color: $primary !important;
  border-color: $primary !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: $primary !important;
  color: #fff !important;
}

.fc .fc-daygrid-day.fc-day-today:hover {
  background-color: darken($primary, 5) !important;
}

.no_margin_bottom {
  margin-bottom: 0 !important;
}

.event__treatment {
  .fc-event-title::before {
    content: "\f06c";
    padding-right: 5px;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
  }
}

.calendar__modal {
  h2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
}

.input__select--search > div.css-yk16xz-control,
.input__select--search > div.css-yk16xz-control:hover {
  border-color: #e5eaee;
}

.input__select--search > div.css-1pahdxg-control {
  border-color: #69b3ff !important;
  box-shadow: none !important;
}

.weather__description {
  font-size: 0.82rem;
}

div.tooltip {
  z-index: 5000;
}

.module__description {
  margin-right: 250px;
  margin-left: 250px;
}

.btn.button--brigadier {
  color: #fff;
  background-color: #ffd100;
  border-color: #ffd100;
}

.btn.button--brigadier:hover {
  color: #fff !important;
  background-color: #f4f39b !important;
  border-color: #f4f39b !important;
}

.aside_buttons_container {
  position: absolute;
  top: 0;
  right: -50px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991.98px) {
    position: fixed;
    top: unset;
    bottom: 70px;
    right: 14px;
  }
  &__button {
    position: relative;
    width: 40px;
    height: 40px;
    @media (max-width: 991.98px) {
      width: 32px;
      height: 32px;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 !important;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.modal__table .table-responsive {
  max-height: 340px;
}

.packaging__table .table-responsive {
  max-height: 400px;
}

.input--no_arrows::-webkit-outer-spin-button,
.input--no_arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal__group .form-label {
  width: 100%;
  margin-top: 6px;
}

.card__table .table-responsive {
  max-height: 52.1vh;
}

.card__table--big .table-responsive {
  max-height: 48.7vh;
}

.card__table--brigadier .table-responsive {
  margin-bottom: 0.5rem;
  max-height: 46.2vh;
}

.card__table--small.card__table--brigadier .table-responsive {
  margin-bottom: 0.5rem;
  max-height: 42.1vh;
}

.application__message {
  margin-top: -10px;
  color: salmon;
  font-size: 10px;
}

.row--level-5 {
  background-color: #d8c9f7 !important;
}

.row--level-4 {
  background-color: #c9e5f7 !important;
}

.dropzone {
  width: 100%;
  height: 125px;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 10px;
  border: 2px dashed rgb(187, 186, 186);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.highlight {
  color: #fff;
  background-color: #79b3f1;
}

.dropzone__input {
  display: none;
}

.cursor__pointer {
  cursor: pointer;
}

.table__freeze thead th {
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 20;
}

.notifications__scroll {
  position: relative;
  max-height: 300px;
}

.text--trial {
  color: $font-trial-color;
}

.text--starter {
  color: $font-starter-color;
}

.text--standard {
  color: $font-standard-color;
}

.text--pro {
  color: $font-pro-color;
}

.height-100 {
  height: 100%;
}

.to-parent-height {
  max-height: inherit;
}

.no-margin {
  margin: 0 !important;
}

//.flex-column {
//  display: flex;
//  flex-direction: column;
//}

.maps-menu__container {
  max-height: calc(78vh - 50px);
}

.scrollable-tab .tab-content {
  overflow-y: auto;
}

.scrollable-container {
  padding: 0;
  overflow-x: hidden;
}

.card-title--center {
  width: 100%;
  justify-content: center;
}

.card-logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card-paragraph {
  text-align: center;
}

.table-full-height .table-responsive {
  max-height: unset !important;
}

.dashboard-search {
  position: sticky;
  top: 119px;
  z-index: 80;
  backdrop-filter: blur(2px);
  padding: 2rem 0;
  background: rgba(255, 255, 255, 0);
  @media (max-width: 992px) {
    top: 55px;
    padding: 1rem 0;
  }
  &::after {
    position: absolute;
    top: 0;
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.23) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0;
    transition: 200ms ease-in-out;
  }
  &.show-bg {
    &::after {
      opacity: 1;
    }
  }
  &__container {
    width: 66.6666%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    &__delete-btn {
      opacity: 0;
      transition: 200ms ease-in-out;
      &.active {
        opacity: 1;
      }
    }
    .MuiFormControl-root {
      width: 75%;
      .MuiInputBase-input {
        font-size: 1.25rem;
      }
    }
    @media (max-width: 768px) {
      width: 85%;
    }
  }
}

@media (max-width: 991.98px) {
  .topbar-mobile-on .topbar {
    z-index: 81;
  }
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.no-pointer-events {
  pointer-events: none;
}

.ag-theme-alpine {
  font-size: 12px !important;
  font-family: inherit !important;
  .ag-row {
    font-size: 12px !important;
    font-family: inherit !important;
    &-hover {
      background-color: transparentize($primary, 0.9) !important;
    }
    &-selected {
      background-color: transparentize($primary, 0.75) !important;
    }
    &-selectable {
      cursor: pointer !important;
    }
  }
  .ag-cell-value {
    line-height: 18px;
  }
}
.manual-pdf-iframe {
  width: 100%;
  height: 60vh;
  border: 0;
}

#scroll-dialog-title-selected-video h2,
.mui-dialog-title__relative h2 {
  position: relative;
}
.mui-dialog {
  h2 {
    position: relative;
  }
  &-popup {
    &__title {
      display: block;
      max-width: calc(100% - 2.5rem);
    }
    &__close-btn {
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-thumbnail__play-btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
    color: rgba(255, 255, 255, 0.75);
  }
}

.video-popup-wrapper {
  height: auto;
  width: 45vw;
  position: relative;
  padding-bottom: 56.25%;
  @media (max-width: 1400px) {
    width: 50vw;
  }
  @media (max-width: 1024px) {
    width: 60vw;
  }
  @media (max-width: 768px) {
    width: 72vw;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.app-pricing-card {
  border: 2px solid #666;
  border-radius: 1rem;
  padding: 1.5rem;
}

.cm-time-switch-label {
  background: rgba(114, 183, 56, 0.15);
  color: #72b738;
  padding: 0.75rem;
  border-radius: 2rem;
  font-weight: bold;
}

.symbol.symbol-green .symbol-label {
  background-color: $primary;
  color: #fff;
}

.opacity-0 {
  opacity: 0;
}

.onboarding-card {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &__h1,
  &__h2 {
    font-weight: 900;
    color: #000;
    line-height: 1.3;
  }
  &__h6 {
    line-height: 1.5;
  }
  &__arrow-button {
    transition: 300ms ease-in-out;
    border-radius: 0.25rem;
    &:disabled {
      cursor: default !important;
    }
  }
}

.line-through {
  text-decoration: line-through;
}

.dashboard-top-workers-list {
  counter-reset: dashboard-top-workers-list-counter;
  list-style: none;
  li {
    margin: 0 0 0.75rem 0;
    counter-increment: dashboard-top-workers-list-counter;
    position: relative;
    font-size: 1.1rem;
    padding-top: 2px;
  }
  li::before {
    content: counter(dashboard-top-workers-list-counter);
    color: #212121;
    font-size: 1.25rem;
    font-weight: bold;
    position: absolute;
    --size: 24px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    border-radius: 50%;
    border: 1px solid #212121;
    text-align: center;
  }
  li:nth-child(1)::before {
    color: white;
    background: rgb(230, 195, 2);
    border: 0;
  }
  li:nth-child(2)::before {
    color: white;
    background: silver;
    border: 0;
  }
  li:nth-child(3)::before {
    color: white;
    background: rgb(97, 59, 59);
    border: 0;
  }
}

.crop-view {
  &__thumb-image {
    width: 50%;
    @media (min-width: 640px) {
      width: 33.3333%;
    }
    @media (min-width: 768px) {
      width: 25%;
    }
    @media (min-width: 1280px) {
      width: 20%;
    }
    @media (min-width: 1440px) {
      width: 16.66666%;
    }
    @media (min-width: 1600px) {
      width: 14.28571429%;
    }
    button {
      width: 100%;
      img {
        display: block;
        width: 100%;
        /*height: 150px;
        width: auto;*/
      }
    }
    &__hover-bg {
      background: transparent;
      transition: 100ms ease-in-out;
      i {
        opacity: 0;
        transition: 100ms ease-in-out;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.35);
        i {
          opacity: 1;
        }
      }
    }
  }
  &__datepicker-field {
    width: 50%;
    margin-bottom: 1rem;
    @media (min-width: 640px) {
      width: 150px;
      margin-bottom: 1.75rem;
    }
  }
  &__datepicker-refresh-btn {
    margin-left: auto;
    margin-bottom: 1.75rem;
    @media (min-width: 640px) {
      margin-left: 0;
    }
  }
  &__preview-control-btn-separator {
    margin: 0;
    background: #80808f;
    height: 1px;
    width: 100%;
  }
}

.calendar__loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 20;
  backdrop-filter: blur(4px);
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc {
  &-toolbar {
    &-chunk {
      display: flex;
      align-items: center;
    }
    &-title {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  &-icon-plus {
    font-family: "Font Awesome 6 Free" !important;
    font-size: 1.25rem !important;
  }
}

@media (max-width: 768px) {
  .fc-header-toolbar {
    flex-direction: column;
    .fc-toolbar-chunk:nth-child(1) {
      margin-bottom: 0.5rem;
    }
  }
}

.header {
  &-logo img {
    height: 27px;
    @media (max-width: 1279.98px) {
      height: 24px;
    }
  }
}

.offer--active {
  background-color: transparentize($primary, 0.75) !important;
}

.bg-primary {
  background-color: $primary !important;
}

.brigadier-theme {
  .btn.btn-primary {
    color: #fff;
    background-color: $primary-brigadier !important;
    border-color: $primary-brigadier !important;
  }

  .btn.btn-primary:hover:not(.btn-text),
  .btn.btn-primary:focus:not(.btn-text),
  .btn.btn-primary.focus:not(.btn-text) {
    color: #fff;
    background-color: darken($primary-brigadier, 5) !important;
    border-color: darken($primary-brigadier, 5) !important;
  }

  .btn.btn-outline-primary {
    color: $primary-brigadier;
    background-color: transparent;
    border-color: $primary-brigadier;
  }

  .btn.btn-outline-primary:hover:not(.btn-text),
  .btn.btn-outline-primary:focus:not(.btn-text),
  .btn.btn-outline-primary.focus:not(.btn-text) {
    color: #fff;
    background-color: $primary-brigadier;
    border-color: $primary-brigadier;
  }

  .btn.btn-outline-primary i {
    color: $primary-brigadier;
  }

  .btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text),
  .btn.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn.btn-outline-primary.dropdown-toggle,
  .show .btn.btn-outline-primary.btn-dropdown {
    color: #fff;
    background-color: $primary-brigadier;
    border-color: $primary-brigadier;
  }
  .ag-theme-alpine {
    .ag-row {
      &-hover {
        background-color: transparentize($primary-brigadier, 0.9) !important;
      }
      &-selected {
        background-color: transparentize($primary-brigadier, 0.75) !important;
      }
    }
  }
  .offer--active {
    background-color: transparentize($primary-brigadier, 0.75) !important;
  }
  .page-link {
    color: $primary-brigadier;
  }

  .page-item.active .page-link {
    background-color: $primary-brigadier;
    border-color: $primary-brigadier;
  }

  .nav .show > .nav-link,
  .nav .nav-link:hover:not(.disabled),
  .nav .nav-link.active {
    color: $primary-brigadier;
  }

  .page-link:hover {
    color: $primary-brigadier;
  }
}
